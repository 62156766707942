import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'

import SocialRegime from '@components/form/SocialRegime'
import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

import useAdultStepHandlers from './useAdultStepHandlers'

const SocialRegimeInformation = () => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const dispatch = useDispatch()
  const {
    form: {
      control,
      handleSubmit,
      setValue,
      formState: {
        errors,
      },
      watch,
    },
  } = useEstimationWizardForm({ })

  const {
    id, backHandler, nextHandler,
  } = useAdultStepHandlers(wizardRoutes.BIRTH_DATE, wizardRoutes.PROFESSION, watch('adults').length)

  const errorType = errors.adults?.[id]?.socialRegime?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectSocialRegime'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>

        <InputGroup
          description={t('wizard:wizard.steps.socialRegime.description')}
          errors={inputErrors}
        >
          <SocialRegime
            control={control}
            error={errorType}
            setValue={setValue}
            label={t('wizard:wizard.steps.socialRegime.label')}
            id={id}
          />
        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={backHandler}/>

    </form>
  )
}

export default SocialRegimeInformation
