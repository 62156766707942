import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'
import {
  useEffect,
  useRef,
} from 'react'

import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import Profession from '@components/form/Profession'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  useGetProfessionQuery,
} from '@root/services/profession'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'

import useAdultStepHandlers from './useAdultStepHandlers'

const ProfessionInformation = () => {
  const dispatch = useDispatch()
  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const {
    data,
  } = useGetProfessionQuery()
  const {
    form: {
      control,
      handleSubmit,
      formState: {
        errors,
        isDirty,
      },
      watch,
      setValue,
      trigger,
      register,
    },
  } = useEstimationWizardForm({})

  const formRef = useRef<HTMLFormElement>(null)

  const {
    id, backHandler, nextHandler,
  } = useAdultStepHandlers(wizardRoutes.SOCIAL_REGIME, wizardRoutes.INSURANCE_GENDER, watch('adults').length)

  const professionRadio = watch(`adults.${id}.professionRadio` as 'adults.0.professionRadio')
  const profession = watch(`adults.${id}.profession` as 'adults.0.profession')

  const errorType = errors.adults?.[id]?.profession?.type
  const professionRadioStatus = useGetRadioStatus(errors.adults?.[id]?.profession?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectProfession'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  useSubmitFormOnChange(isDirty, professionRadio, formRef)

  useEffect(() => {
    if (profession !== null) {
      setValue(`adults.${id}.professionRadio` as 'adults.0.professionRadio', null)
      trigger(`adults.${id}.professionRadio` as 'adults.0.professionRadio')
    }
  }, [profession, setValue, trigger, id])

  useEffect(() => {
    if (professionRadio !== null) {
      setValue(`adults.${id}.profession` as 'adults.0.profession', null)
      trigger(`adults.${id}.profession` as 'adults.0.profession')
    }
  }, [professionRadio, setValue, trigger, id])

  if (!data) {
    return null
  }

  const {
    items,
    mostPopularProfessions,
  } = data

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup
          errors={inputErrors}
        >
          <RadioStackedContainer>
            {mostPopularProfessions.slice(0, 3).map(profession => (
              <RadioStacked
                key={profession.id}
                id={profession.id}
                item={{
                  label: [
                    profession.label,
                    ''
                  ],
                  value: profession.id,
                }}
                status={professionRadioStatus}
                {...register(`adults.${id}.professionRadio` as 'adults.0.professionRadio', {
                  validate: {
                    required: (value, {
                      adults,
                    }) => {
                      return value !== null || adults[id].profession !== null
                    },
                  },
                })}
              />
            ))}
          </RadioStackedContainer>

          <div className='py-2 text-center text-neutral-400'>
          ou
          </div>

          <Profession
            items={items}
            control={control}
            error={errorType}
            label={t('wizard:wizard.steps.profession.label')}
            id={id}
          />
        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={backHandler}/>

    </form>
  )
}

export default ProfessionInformation
