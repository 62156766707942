import {
  matchPath, useLocation,
  useParams,
} from 'react-router-dom'
import {
  useWatch,
} from 'react-hook-form'

import useEstimationWizardForm from '@hooks/useWizardForm'
import {
  numberOfAdultQuestions, numberOfChildQuestions,
} from '@root/domain/Estimation'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState,
} from '@root/services/formSlice'
import {
  steps,
} from '@root/routes/wizard'
import {
  Step,
} from '@root/domain/Wizard'
import {
  numberOfAdults,
} from '@root/domain/Beneficiary'

import {
  findCurrentStep,
} from './helper'

const useGetWizardRouteInformation = () => {
  const {
    pathname,
  } = useLocation()

  const currentRoute = steps.find(step => matchPath(step.path, pathname)) as Step
  const currentStep = steps.findIndex(step => matchPath(step.path, pathname))

  const state = useAppSelector(state => getFormState(state))

  const {
    form: {
      control,
    },
  } = useEstimationWizardForm({})

  const {
    beneficiary, amountOfChildren,
  } = state

  const what = useWatch({
    control,
  })

  const {
    id,
  } = useParams<{id: string}>()

  const amountOfChildrenNumber = parseInt(amountOfChildren, 10) || 0
  const adultDynamicSteps = beneficiary ? (numberOfAdults[beneficiary] * numberOfAdultQuestions) - numberOfAdultQuestions : 0
  const childrenDinamicSteps = Math.max((amountOfChildrenNumber * numberOfChildQuestions) - numberOfChildQuestions, 0)

  const totalSteps = steps.filter(step => step.shouldRender(state)).length + adultDynamicSteps + childrenDinamicSteps

  const isAdultRoute = currentRoute?.path.includes('information-beneficiaire')
  const isChildRoute = currentRoute?.path.includes('age-enfant')

  const adultQuestionStartIndex = steps.findIndex(step => step.path.includes('information-beneficiaire'))
  const childrenQuestionStartIndex = steps.findIndex(step => step.path.includes('age-enfant'))

  const isBeforeAdultRoutes = currentStep < adultQuestionStartIndex
  const isBeforeChildrenRoutes = currentStep < childrenQuestionStartIndex

  const adultIteration = isAdultRoute && id ? parseInt(id, 10) : isBeforeAdultRoutes ? 0 : state.adults.length
  const childIteration = isChildRoute && id ? parseInt(id, 10) : isBeforeChildrenRoutes ? 0 : state.children.length - 1

  return {
    currentRoute,
    currentStep: findCurrentStep(steps, currentRoute, state.adults, state.children, adultIteration, childIteration, state),
    steps,
    totalSteps,
    what,
  }
}

export default useGetWizardRouteInformation
