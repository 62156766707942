import {
  Button,
  ButtonSize,
  ButtonType,
  Caption,
  Subheading, SubheadingType,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'

import Icon from '@components/Icon'

import {
  MerTypeProps,
} from '.'

const RequestCallbackSelectra: FC<MerTypeProps> = ({
  type,
  offerId,
  estimationId,
}) => {
  return (
    <div className='flex flex-col gap-6'>
      { type === ButtonType.PRIMARY && (
        <div>
          <Subheading type={SubheadingType.TERTIARY}>
          Souscrire en quelques minutes par téléphone
          </Subheading>
        </div>
      )}
      <div className='flex flex-col items-center gap-4'>
        <a className='w-full' href={'tel:01 86 65 26 10'}>
          <Button variant={type}
            pill
            size={ButtonSize.MEDIUM}
            label='01 86 65 26 10'
            iconLeft={() => (
              <Icon width={20} height={20}
                name='phone'
              />
            )}
          >
          01 86 65 26 10
          </Button>
        </a>
        <Caption variant={TextVariant.REGULAR}>
        ou&nbsp;
          <Link to={`/offre/${estimationId}/${offerId}/mer/callback-selectra/`}
            className='text-primary-400 underline hover:text-primary-450'
          >
            faites-vous rappeler gratuitement</Link>
        </Caption>
      </div>
    </div>
  )
}

export default RequestCallbackSelectra
