import {
  Outlet,
  createBrowserRouter,
} from 'react-router-dom'

import App from '@root/App'
import {
  OffersPage, Wizard,
} from '@root/pages'
import Mer from '@components/ui/SubscriptionModal/Mer'
import SubscriptionSuccess from '@components/ui/SubscriptionModal/v2/SubscriptionSuccess'
import CallbackContainer from '@components/ui/SubscriptionModal/v2/CallbackContainer'
import RequestCallbackAction from '@components/ui/SubscriptionModal/v2/actions/RequestCallbackAction'
import CallbackSuccess from '@components/ui/SubscriptionModal/v2/CallbackSuccess'
import RequestCallbackSelectraAction from '@components/ui/SubscriptionModal/v2/actions/RequestCallbackSelectraAction'

import {
  routes,
} from './wizard'

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'offre/:estimationId/*?',
        element: <OffersPage />,
        children: [
          {
            path: ':offerId/mer',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Mer />,
              },
              {
                path: 'subscription/:merId',
                element: <SubscriptionSuccess />,
              },
              {
                path: 'callback',
                element: <CallbackContainer>
                  <RequestCallbackAction />
                </CallbackContainer>,
              },
              {
                path: 'callback/:merId',
                element: <CallbackSuccess />,
              },
              {
                path: 'callback-selectra',
                element: <CallbackContainer>
                  <RequestCallbackSelectraAction />
                </CallbackContainer>,
              },
              {
                path: 'callback-selectra/:merId',
                element: <CallbackSuccess />,
              }
            ],
          }
        ],
      },
      {
        path: '*',
        element: <Wizard />,
        children: [
          ...routes
        ],
      }
    ],
  }
])
