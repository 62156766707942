export enum EstimationStatus {
  COMPLETE = 'complete',
  FETCHING = 'fetching',
  CREATED = 'created',
}

export enum ResidenceType {
  Principal = 'principal',
  Secondary = 'secondary',
}

export interface Children {
  id: string
  birthDate: string
}

export interface Adult {
  id: string
  gender: string
  birthDate: string
  professionId: string
  socialRegimeId: string
  type: string
}

export interface EstimationAddress {
  postalCode: string
  locality: string
  country: string
  formattedAddress: string
}

export interface Estimation {
  id: string
  status: string
  consultation: string
  hospitalization: string
  optical: string
  dental: string
  children: Children[]
  adults: Adult[]
  contractStartDate: string
  address: EstimationAddress
  userId: string
}

export const numberOfAdultQuestions = 4
export const numberOfChildQuestions = 1
