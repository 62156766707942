import {
  Dispatch,
  FC,
  SetStateAction,
} from 'react'
import {
  InteractionMessageAgent,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import HelpCircle from '@assets/icons/help-circle.svg?react'
import FrenchFlag from '@assets/icons/french-flag.svg?react'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'
import {
  readablePhoneNumber,
} from '@root/util/formatting'

import {
  HELP_CALLBACK_REQUEST,
} from './routes'

interface HelpInformationProps {
  setLocation: Dispatch<SetStateAction<string>>
}

const HelpInformation: FC<HelpInformationProps> = ({
  setLocation,
}) => {
  const {
    t,
  } = useTranslation(['modal'])

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP]

  return (
    <>
      <h3 className="flex gap-2 font-semibold md:text-lg">
        <HelpCircle /> {t('modal:modal.help.title')}
      </h3>
      <p className='text-sm text-neutral-400'> {t('modal:modal.help.description')}</p>
      <div className="flex flex-col items-center gap-4 rounded-lg border border-neutral-150 p-4">
        <a href="tel:+33 1 86 65 26 10" className='flex items-center gap-4 text-2xl font-semibold text-primary-400'>
          <InteractionMessageAgent />{readablePhoneNumber(phoneNumber)}<FrenchFlag />
        </a>
        <div className="self-stretch text-center">
          <span className="text-base font-normal text-zinc-600">ou</span>
          <span className="text-base font-normal text-sky-700"> </span>
          <button role="tab" aria-label={HELP_CALLBACK_REQUEST}
            onClick={() => {
              setLocation(HELP_CALLBACK_REQUEST)
            }} className="cursor-pointer text-base font-normal text-sky-700 underline"
          >faites-vous rappeler gratuitement</button>
        </div>
      </div>
    </>
  )
}

export default HelpInformation
