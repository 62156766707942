import common from './common'
import wizard from './wizard'
import validation from './validation'
import offers from './offers'
import modal from './modal'

export default {
  common,
  wizard,
  validation,
  offers,
  modal,
}
