import {
  ProgressBar as ProgressBarSelectra,
} from '@selectra-it/selectra-ui'

import useGetWizardRouteInformation from '@hooks/wizard/useGetWizardRouteInformation'

const ProgressBar = () => {
  const {
    currentStep,
    totalSteps,
  } = useGetWizardRouteInformation()

  const percentage = (currentStep / totalSteps) * 100

  return (
    <div className='container'>
      <ProgressBarSelectra percentage={percentage} />
    </div>
  )
}

export default ProgressBar
