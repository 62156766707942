import {
  FC, useState,
} from 'react'
import {
  Control, Controller, LiteralUnion, RegisterOptions,
} from 'react-hook-form'
import {
  Combobox,
} from '@selectra-it/selectra-ui'

import {
  Profession,
} from '@root/services/profession'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import useGetInputStatus from '@hooks/useGetInputStatus'

interface ProfessionProps {
  control: Control<WizardFormData>
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
  label: string
  id: number;
  items: Profession[]
}

const ProfessionComponent: FC<ProfessionProps> = ({
  control,
  error,
  label,
  id,
  items,
}) => {
  const [query, setQuery] = useState<string>('')
  const status = useGetInputStatus(error)

  if (!items) return null

  const displayValue = (value: Profession | null): string => value?.label ?? ''

  const filteredItems =
      query === ''
        ? items
        : (items.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase())
        ))

  return (
    <Controller
      name={`adults.${id}.profession`}
      control={control}
      rules={{
        validate: {
          required: (value, {
            adults,
          }) => {
            return value !== null || adults[id].professionRadio !== null
          },
        },
      }}
      render={renderParams => {
        const {
          field: {
            value,
            ref,
            name,
            onChange,
          },
        } = renderParams

        return (
          <Combobox<Profession>
            id='profession'
            ref={ref}
            displayValue={displayValue}
            item={value}
            setQuery={setQuery}
            setItem={onChange}
            items={filteredItems}
            label={label}
            status={status}
            name={name}
            readOnly={true}
            canClearValue={false}
            dataCy="profession"
            immediate={true}
          />
        )
      }}
    />
  )
}

export default ProfessionComponent
