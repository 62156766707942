import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC, MouseEventHandler,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import Arrow from '@assets/icons/arrow-backward.svg?react'

interface NavigationButtonsProps {
  onBackHandler?: MouseEventHandler<HTMLButtonElement>
  nextLabel?: string;
  disabled?: boolean;
}

const NavigationButtons: FC<NavigationButtonsProps> = ({
  onBackHandler,
  nextLabel,
  disabled,
}) => {
  const {
    t,
  } = useTranslation(['wizard'])

  const submitLabel = nextLabel ?? t('wizard:wizard.steps.next')

  return (
    <div className='flex gap-5'>
      {onBackHandler && (
        <Button
          type='button'
          variant={ButtonType.LIGHT}
          size={ButtonSize.LARGE}
          pill
          rounded
          iconLeft={Arrow}
          onClick={onBackHandler}
        />
      )}

      <Button
        type='submit'
        disabled={disabled}
        label={submitLabel}
        variant={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        pill
        dataCy={'submit'}
      />
    </div>
  )
}

export default NavigationButtons
