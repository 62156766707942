import {
  useTranslation,
} from 'react-i18next'
import {
  Button, ButtonSize, ButtonType, getErrorMessages, ErrorMessages, InputGroup,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import {
  v4,
} from 'uuid'
import {
  useNavigate,
} from 'react-router-dom'

import dayjs from '@root/plugins/day'
import MobileGray from '@assets/icons/mobile-gray.svg?react'
import Check from '@assets/icons/check.svg?react'
import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  useCreateOfferMerMutation, useGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  PhoneNumber,
} from '@root/domain/PhoneNumber'
import CallbackRequestDate, {
  CallbackDate,
} from '@components/form/CallbackDateInput'
import CallbackDateTime, {
  CallbackTime,
} from '@components/form/CallbackTimeInput'
import {
  Offer,
} from '@root/domain/Offer'
import {
  Insurer,
} from '@root/domain/Insurer'
import {
  useFetchUserQuery, useUpdateUserMutation,
} from '@root/services/user'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import {
  ImmediateMerCallbackDate, ScheduledMerCallbackDate,
} from '@root/domain/crm/Record'
import {
  callbackDateNow,
} from '@root/domain/OfferMer'

interface CallbackFormProps {
  insurer: Insurer,
  offer: Offer
}

interface CallbackFormState {
  phoneNumber: PhoneNumber,
  callback_time: CallbackTime | null,
  callback_date: CallbackDate,
}

export const formatCallbackDateTime = (date: CallbackDate, time: CallbackTime | null): ImmediateMerCallbackDate | ScheduledMerCallbackDate => {
  if (date.id === 'now') {
    return {
      isImmediate: true,
      date: null,
    }
  }

  return {
    isImmediate: false,
    date: dayjs(`${date?.id} ${time?.id}`).toISOString(),
  }
}

const CallbackForm: FC<CallbackFormProps> = ({
  insurer,
  offer,
}) => {
  const navigate = useNavigate()
  const {
    t,
  } = useTranslation(['modal', 'wizard', 'validation'])
  const getEstimation = useGetOffersByEstimationIdQuery({
    estimationId: offer.estimationId,
  })
  const fetchUser = useFetchUserQuery(getEstimation.data?.estimation?.userId ?? '')

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<CallbackFormState>({
    defaultValues: {
      callback_time: null,
      callback_date: callbackDateNow,
      phoneNumber: {
        number: fetchUser.data?.phoneNumber ?? '',
      },
    },
  })

  const [updateUser] = useUpdateUserMutation()
  const [createMer, result] = useCreateOfferMerMutation()

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  if (!fetchUser.data) {
    return <></>
  }

  const onSubmitHandler:SubmitHandler<CallbackFormState> = async data => {
    const id = v4()

    await updateUser({
      uuid: fetchUser?.data?.id,
      number: data.phoneNumber.number,
      country_code: 'FR',
    })

    await createMer({
      id,
      type: callbackDate?.id === 'now' ? 'IMMEDIATE_CALLBACK' : 'CALLBACK_REQUEST',
      offerId: offer.id,
      estimationId: offer.estimationId,
      callback_date: callbackDate?.id === 'now' ? null : formatCallbackDateTime(callbackDate, callbackTime),
    })

    navigate(`/offre/${offer.estimationId}/${offer.id}/mer/callback/${id}`)
  }

  const callbackDate = watch('callback_date')
  const callbackTime = watch('callback_time')

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPhoneNumber'),
    phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
  }, errors.phoneNumber?.number?.type)

  const dateInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callback_date?.type)

  const dateTimeInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callback_time?.type)

  return (
    <form
      className='md:p-2'
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <h3 className='mb-4 flex gap-2 font-semibold md:mb-6'>
        <MobileGray className='shrink-0'/>
        {insurer.name} {t('modal:modal.callback.title')}
      </h3>

      <ul className='mb-4 flex flex-col gap-2'>
        <li className='flex items-start gap-1'> <Check className='shrink-0'/> {t('modal:modal.callback.item1')}</li>
        <li className='flex items-start gap-1'> <Check className='shrink-0'/> {t('modal:modal.callback.item2', {
          insurer: insurer.name,
        })}</li>
      </ul>

      <div className='flex flex-col gap-6'>
        <InputGroup errors={inputErrors}>
          <PhoneNumberInput<CallbackFormState>
            label={t('wizard:wizard.steps.phoneNumber.label')}
            name='phoneNumber.number'
            register={register}
            status={phoneNumberInputStatus}
          />
        </InputGroup>

        <InputGroup errors={dateInputErrors}>
          <Controller
            name='callback_date'
            control={control}
            rules={{
              required: true,
            }}
            render={renderParams => {
              const {
                field: {
                  value,
                  ref,
                  name,
                  onChange,
                },
              } = renderParams

              return (
                <CallbackRequestDate value={value} ref={ref}
                  name={name} onChange={onChange}
                  error={errors.callback_date?.type}
                />
              )
            }}
          />
        </InputGroup>

        { callbackDate && callbackDate.id !== 'now' &&
          <InputGroup errors={dateTimeInputErrors}>
            <Controller
              name='callback_time'
              control={control}
              rules={{
                required: true,
              }}
              render={renderParams => {
                const {
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                  },
                } = renderParams

                return (
                  <CallbackDateTime value={value} ref={ref}
                    name={name} onChange={onChange}
                    error={errors.callback_time?.type}
                  />
                )
              }}
            />
          </InputGroup>
        }
      </div>

      <div className='mt-6'>
        <Button
          type='submit'
          disabled={result.isLoading}
          label={t('modal:modal.callback.button')}
          variant={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM}
          pill
        />
      </div>

    </form>
  )
}

export default CallbackForm
