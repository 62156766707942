import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGetOffersByEstimationId = (estimationId: string, params?: any) => {
  const result = useGetOffersByEstimationIdQuery({
    estimationId,
  }, {
    skip: !estimationId,
    ...params,
  })

  return {
    ...result,
  }
}

export default useGetOffersByEstimationId
