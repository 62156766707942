import {
  FC,
} from 'react'

import {
  CustomerService,
} from '@root/domain/Insurer'
import Chat from '@assets/icons/chat.svg?react'
import Phone from '@assets/icons/smartphone.svg?react'

const IconMap = {
  [CustomerService.online_chat]: Chat,
  [CustomerService.phone]: Phone,
}

interface CustomerServiceIconProps {
  type: CustomerService
}

const CustomerServiceIcon: FC<CustomerServiceIconProps> = ({
  type,
}) => {
  const Icon = IconMap[type]

  return (
    <Icon className='inline-block fill-success-300' height={16}
      width={16}
    />
  )
}

export default CustomerServiceIcon
