import {
  RefObject, useEffect,
} from 'react'

import {
  Profession,
} from '@root/services/profession'

const useSubmitFormOnChange = (
  isDirty: boolean,
  changes: string | boolean | null | Profession,
  formRef: RefObject<HTMLFormElement>
) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (isDirty && changes !== null) {
      timeout = setTimeout(() => {
        formRef?.current?.requestSubmit()
      }, 400)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isDirty, changes, formRef])
}

export default useSubmitFormOnChange
