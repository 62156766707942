import {
  useParams,
} from 'react-router-dom'

import {
  useFetchUserQuery,
} from '@root/services/user'

import useGetOffersByEstimationId from './useGetOffersByEstimationId'

const useGetUserFromEstimationIdRouteParam = () => {
  const {
    estimationId,
  } = useParams<'estimationId'>()
  const {
    data,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId as string)

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  return {
    fetchUser,
    estimationId,
  }
}

export default useGetUserFromEstimationIdRouteParam
