import {
  Nullable,
} from './Wizard'

export enum Coverage {
  MIN = 'MIN',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export interface Guarantee {
  id: string;
  franchise: number;
  premium: number;
  threshold: number,
  coverage: Coverage;
}

export interface Document {
  id: string;
  type: string;
  name: string;
}

export interface Pack {
  id: string;
  premium: number;
  coverages: Coverage[];
}

export interface Offer {
  id: string;
  estimationId: string;
  insurerId: string;
  providerId: Nullable<string>;
  title: string;
  coverage: Record<string, Coverage>;
  externalId: string;
  price: number;
  monthlyPrice: number;
  franchise: number,
  closingType: string;
  guarantees: Guarantee[];
  url: string;
  documents: Document[];
  packs: Pack[];
  rating: number,
  rank: number,
  matchRate: number,
  alternativeMedicine: boolean,
  responsibleContract: boolean
}

export enum MerType {
  SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST',
  IMMEDIATE_CALLBACK = 'IMMEDIATE_CALLBACK',
  CALLBACK_REQUEST = 'CALLBACK_REQUEST',
}
