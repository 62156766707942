import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  tagColors,
} from '@hooks/offers/useGetEstimationTags'
import {
  Tags,
} from '@root/services/offers'

interface FlagProps {
  tag: keyof Tags
}
const Flag: FC<FlagProps> = ({
  tag,
}) => {
  const {
    t,
  } = useTranslation(['offers'])
  const color = tagColors[tag]

  return (
    <span className={`rounded ${color.background} px-4 py-2 font-semibold text-white`}>
      {t(`offers:tags.${tag}`)}
    </span>
  )
}
export default Flag
