import {
  useEffect,
} from 'react'
import {
  animated, useSpring,
} from '@react-spring/web'
import {
  useParams,
} from 'react-router-dom'

import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  useFetchUserQuery,
} from '@root/services/user'
import Banner from '@components/ui/Banner'
import {
  EstimationStatus,
} from '@root/domain/Estimation'
import CallbackBanner from '@components/offers/CallbackBanner'
import {
  useGetInsurersQuery,
} from '@root/services/insurers'

import OfferItem from './OfferItem'
import OfferMessage from './OfferMessage'

const Offers = () => {
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  useGetInsurersQuery()

  const {
    data,
    refetch,
    error,
    isSuccess,
  } = useGetOffersByEstimationIdQuery({
    estimationId,
  })

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.estimation?.status === EstimationStatus.COMPLETE || error) {
        clearInterval(interval)
        return
      }

      refetch()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [data, refetch, error])

  const [offerListStyle] = useSpring({
    from: {
      transform: 10,
      opacity: 0,
    },
    to: {
      transform: fetchUser.isSuccess && isSuccess ? 0 : 10,
      opacity: fetchUser.isSuccess && isSuccess ? 1 : 0,
    },
    leave: [
      {
        transform: 'none',
      }
    ],
  }, [fetchUser, isSuccess])

  const items = data?.items && data.items.map(item =>
    <OfferItem offer={item} key={item.id} />
  )

  const withBanner = items?.length
    ? [...items.slice(0, 3),
      <CallbackBanner
        key='callback-banner'
        title='Vous n’êtes pas sûr(e) ?'
      />, ...items.slice(3, items.length)]
    : [
      <CallbackBanner key='callback-banner'
        title='Besoin d’aide ?'
      />]

  return (
    <div className='container flex h-full flex-col gap-8 md:gap-12'>
      <OfferMessage user={fetchUser.data} isSuccess={fetchUser.isSuccess}
        offers={data?.items}
      />

      {!data?.items.length &&
        <div className='p-4'>
          <Banner text="Les offres proposées ont un ou plusieurs niveaux de garantie plus faible que ce que vous avez demandé." />
        </div>}

      <animated.div className='flex w-full flex-col gap-4 md:gap-12' role="list"
        style={{
          ...offerListStyle,
          transform: offerListStyle.transform.to(t => {
            return (t ? `translateY(${t}%)` : 'none')
          }),
        }}
      >
        {...withBanner}
      </animated.div>
    </div>
  )
}

export default Offers
