import {
  Record,
} from '@root/domain/crm/Record'

import {
  apiService,
} from '../apiService'

export const CrmApi = apiService
  .enhanceEndpoints({
    addTagTypes: ['Offers'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      createRecord: builder.mutation<null, Record>({
        query: data => ({
          url: 'api/crm/v2/records',
          method: 'POST',
          body: {
            ...data,
          },
        }),
      }),
    }
    ),
  })

export const {
  useCreateRecordMutation,
} = CrmApi
