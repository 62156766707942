import {
  Button,
  ButtonSize,
  ButtonType,
  Subheading, SubheadingType,
  Text,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'

import Icon from '@components/Icon'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  selectOfferById,
} from '@root/services/offers/selectors'

import {
  MerTypeProps,
} from '.'

const RequestCallback: FC<MerTypeProps> = ({
  type,
  offerId,
  estimationId,
}) => {
  const offer = useAppSelector(state => selectOfferById(estimationId, offerId)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  return (
    <div className='flex flex-col gap-6'>
      { type === ButtonType.PRIMARY && (
        <>
          <Subheading type={SubheadingType.TERTIARY}>
          Souscrire en quelques minutes par téléphone
          </Subheading>
          <Text variant={TextVariant.REGULAR}>
          Laissez vos coordonnées et {insurer?.name} vous rappellera pour finaliser votre souscription.
          </Text>
        </>
      )}
      <div className='flex flex-col items-center gap-4'>
        <Link to={`/offre/${estimationId}/${offerId}/mer/callback/`}
          className='flex w-full text-primary-400 underline hover:text-primary-450'
        >
          <Button variant={type}
            pill
            size={ButtonSize.MEDIUM}
            label='Rappel de l’assureur'
            iconLeft={() => <Icon name='phone' width={20} />}
          />
        </Link>
      </div>
    </div>
  )
}

export default RequestCallback
