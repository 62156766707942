export enum SIZE {
  SM,
  MD,
  LG,
  XL,
}

const sizes: Record<SIZE, string> = {
  [SIZE.SM]: 'w-4 h-4',
  [SIZE.MD]: 'w-8 h-8',
  [SIZE.LG]: 'w-12 h-12',
  [SIZE.XL]: 'w-16 h-16',
}

export default sizes
