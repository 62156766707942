import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

import {
  Document,
} from '@root/domain/Offer'

interface DocumentListProps {
  document: Document,
}

const DocumentListItem: FC<DocumentListProps> = ({
  document,
}) => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/documents/${document.id}`

  const {
    t,
  } = useTranslation(['offers'])

  const documentName = t(`offers:document:${document.type}` as 'offers:document.IPID')

  return (
    <Link
      className='mt-2 text-sm text-primary-400 underline md:text-base'
      to={url}
      target='_blank'
    >
      {documentName}
    </Link>
  )
}

export default DocumentListItem
