import {
  FC,
} from 'react'

import InfoCircle from '@assets/icons/info-circle.svg?react'

interface BannerProps {
  text: string
}

const Banner: FC<BannerProps> = ({
  text,
}) => {
  return (
    <div className='flex items-center gap-2 rounded-lg border border-[#A0BFFA] bg-blue-50 p-2 md:gap-4 md:p-4'>
      <InfoCircle className='shrink-0'/>
      <p className='text-sm text-primary-400 lg:text-base'>{text}</p>
    </div>)
}

export default Banner
