export default {
  validation: {
    form: {
      plateNumberUnknown: 'Cliquez sur “Je ne l’ai pas” si vous ne connaissez pas le numéro de plaque d’immatriculation.',
      boolean: {
        required: 'Sélectionnez une option ci-dessus afin de poursuivre.',
      },
      addCirculationDate: 'Veuillez saisir the date of putting into circulation to continue in the ci-dessus.',
      mustSelectAnOption: 'Merci de sélectionner une option ci-dessus afin de continuer',
      mustSelectBeneficiary: 'Sélectionnez une option parmi la liste afin que je crée des devis chez chaque assureur.',
      mustSelectInsuranceGender: 'Sélectionnez une option afin que je crée des devis chez chaque assureur.',
      addBirthDate: 'Veuillez saisir votre date de naissance dans le champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
      mustSelectProfession: 'Sélectionnez une option dans le menu déroulant afin de poursuivre. Cette information est prise en compte par les assureurs.',
      mustSelectSocialRegime: 'Sélectionnez une option dans le menu déroulant afin de poursuivre. Cette information est prise en compte par les assureurs.',
      addChildren: "Veuillez saisir le nombre d'enfants dans le champ ci-dessus.",
      addPostalCode: 'Veuillez saisir votre code postal dans champ ci-dessus afin de poursuivre. Les assureurs ne demandent pas l’adresse complète. Ex : 75000 Paris',
      addInsuranceDate: 'Veuillez saisir votre date de debut de contrat dans champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
      addLevels: 'Sélectionnez un niveau ci-dessus afin de poursuivre vers la page de comparaison.',
      addFirstName: 'Les assureurs ajoutent vos coordonnées sur les devis personnalisés, merci de les renseigner.',
      addEmail: 'Les assureurs demandent votre email pour vous identifier, merci de le renseigner. Selectra ne vous enverra pas d’email.',
      addChildrenBirthDate: 'Veuillez saisir votre date de naissance dans champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
      addPhoneNumber: 'J’ai besoin de votre numéro de téléphone  afin de vous contacter plus tard si vous le souhaitez.',
      invalidPhoneNumber: 'Le numéro de téléphone semble être invalide. Veuillez vérifier le format.',
    },
  },
}
