import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

export const getComputedNextStep = (isLastStep: boolean, isLastIteration: boolean, nextStep: wizardRoutes): wizardRoutes => {
  if (isLastStep && isLastIteration) {
    return nextStep
  }

  if (isLastStep) {
    return wizardRoutes.INSURANCE_GENDER
  }

  return nextStep
}

export const getComputedPreviousStep = (isFirstStep: boolean, isFirstIteration: boolean, previousStep: wizardRoutes): wizardRoutes => {
  if (isFirstStep && isFirstIteration) {
    return previousStep
  }

  if (isFirstStep) {
    return wizardRoutes.BIRTH_DATE
  }

  return previousStep
}
