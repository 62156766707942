import {
  ReactNode,
} from 'react'

import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const messages: Record<wizardRoutes, (state: WizardFormData, pathname: string) => Array<string | ReactNode>> = {
  [wizardRoutes.PRESENTATION]: () => [
    'Bonjour, je m\'appelle Pauline. <strong>Comparons ensemble</strong> les contrats de mutuelle santé.',
    '<svg class="inline-block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Check" clip-path="url(#clip0_2564_52277)"><path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M20.8334 -0.833252H20.0001V8.13603e-05H20.8334V-0.833252ZM7.64113 14.6038C7.73836 14.6388 7.84252 14.6563 7.95363 14.6563C8.06474 14.6563 8.16891 14.6388 8.26613 14.6038C8.36336 14.5694 8.45363 14.5105 8.53697 14.4272L16.2036 6.7605C16.3564 6.60772 16.4328 6.40966 16.4328 6.16633C16.4328 5.92355 16.3564 5.72578 16.2036 5.573C16.0509 5.42022 15.8531 5.34383 15.6103 5.34383C15.367 5.34383 15.1689 5.42022 15.0161 5.573L7.95363 12.6355L4.97447 9.65633C4.82169 9.50355 4.62725 9.42716 4.39113 9.42716C4.15502 9.42716 3.96058 9.50355 3.8078 9.65633C3.65502 9.80911 3.57502 10.0069 3.5678 10.2497C3.56113 10.493 3.63419 10.6911 3.78697 10.8438L7.3703 14.4272C7.45363 14.5105 7.54391 14.5694 7.64113 14.6038Z" fill="#139623"/></g><defs><clipPath id="clip0_2564_52277"><rect width="20" height="20" fill="white"/></clipPath></defs></svg> <strong>Devis en 3 min</strong> top chrono',
    '<svg class="inline-block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Check" clip-path="url(#clip0_2564_52277)"><path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M20.8334 -0.833252H20.0001V8.13603e-05H20.8334V-0.833252ZM7.64113 14.6038C7.73836 14.6388 7.84252 14.6563 7.95363 14.6563C8.06474 14.6563 8.16891 14.6388 8.26613 14.6038C8.36336 14.5694 8.45363 14.5105 8.53697 14.4272L16.2036 6.7605C16.3564 6.60772 16.4328 6.40966 16.4328 6.16633C16.4328 5.92355 16.3564 5.72578 16.2036 5.573C16.0509 5.42022 15.8531 5.34383 15.6103 5.34383C15.367 5.34383 15.1689 5.42022 15.0161 5.573L7.95363 12.6355L4.97447 9.65633C4.82169 9.50355 4.62725 9.42716 4.39113 9.42716C4.15502 9.42716 3.96058 9.50355 3.8078 9.65633C3.65502 9.80911 3.57502 10.0069 3.5678 10.2497C3.56113 10.493 3.63419 10.6911 3.78697 10.8438L7.3703 14.4272C7.45363 14.5105 7.54391 14.5694 7.64113 14.6038Z" fill="#139623"/></g><defs><clipPath id="clip0_2564_52277"><rect width="20" height="20" fill="white"/></clipPath></defs></svg> Économisez <strong>jusqu\'à 300€</strong>'
  ],
  [wizardRoutes.BENEFICIARY]: () => [
    '<strong>Qui</strong> souhaitez-vous assurer ?'
  ],
  [wizardRoutes.INSURANCE_GENDER]: (state, pathname) => {
    const id = pathname.split('/')[2]
    const message = id === '1' ? 'Passons à votre conjoint(e), il/elle est :' : 'Commençons par votre profil, vous êtes :'

    return [message]
  },
  [wizardRoutes.BIRTH_DATE]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa <strong>date de naissance</strong> est le :' : 'Votre <strong>date de naissance</strong> est le :'

    return [message]
  },
  [wizardRoutes.PROFESSION]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa <strong>profession</strong> ou activité est :' : 'Votre <strong>profession</strong> ou activité est :'

    return [message]
  },
  [wizardRoutes.SOCIAL_REGIME]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Son <strong>régime social</strong> est :' : 'Votre <strong>régime social</strong> est :'

    return [message]
  },
  [wizardRoutes.CHILDREN]: () => [
    "Combien d'enfants avez-vous ?"
  ],
  [wizardRoutes.CHILDREN_BIRTH_DATE]: (state, pathname) => {
    interface Childs {
      [key: number]: string;
    }
    const childs:Childs = {
      0: 'premier',
      1: 'deuxième',
      2: 'troisième',
      3: 'quatrième',
      4: 'cinquième',
      5: 'sixième',
      6: 'septième',
      7: 'huitième',
      8: 'neuvième',
      9: 'dixième',
    }
    const hasMoreThanOneChild = parseInt(state.amountOfChildren) > 1
    const id = parseInt(pathname.split('/')[2]) ?? 0

    const message = !hasMoreThanOneChild ? 'La date de naissance de votre enfant est le :' : `La date de naissance de votre ${childs[id]} enfant est le :`

    return [message]
  },
  [wizardRoutes.POSTAL_CODE]: () => [
    'Votre <strong>code postal</strong> est le:'
  ],
  [wizardRoutes.INSURANCE_DATE]: () => [
    'Quelle date de <strong>début de contrat</strong> souhaitez-vous sur vos devis ?'
  ],
  [wizardRoutes.REGULAR_HEALTH_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins courants</strong> ?'
  ],
  [wizardRoutes.HOSPITAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous en cas d\'<strong>hospitalisation</strong> ?'
  ],
  [wizardRoutes.OPTICAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins optiques</strong> ?'
  ],
  [wizardRoutes.DENTAL_LEVEL]: () => [
    'Quelle couverture souhaitez-vous pour vos <strong>soins dentaires</strong> ?'
  ],
  [wizardRoutes.EMAIL]: () => [
    'Et pour finir, nous avons besoin de vos coordonnés pour afficher vos <strong>devis personnalisés</strong> 😉'
  ],
}

export default messages
