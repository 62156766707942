import {
  InputGroup, getErrorMessages, ErrorMessages, TextInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  generatePath,
  useNavigate,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'
import {
  SubmitHandler,
} from 'react-hook-form'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const Children: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    form: {
      handleSubmit,
      register,
      formState: {
        errors,
      },
    },
    onBackHandler,
  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const status = useGetInputStatus(errors?.children?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addChildren'),
    min: t('validation:validation.form.addChildren'),
  }, errors?.amountOfChildren?.type)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))

    if (parseInt(data.amountOfChildren) > 0) {
      const path = generatePath(wizardRoutes.CHILDREN_BIRTH_DATE, {
        id: '0',
      })
      return navigate(`/${path}`)
    }

    navigate(`/${wizardRoutes.EMAIL}`)
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup
          errors={inputErrors}
        >
          <TextInput label={t('wizard:wizard.steps.children.label')}
            status={status}
            {...register('amountOfChildren', {
              required: true,
              min: 1,
            })}
            type="number"
            dataCy={'children'}
          />

        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default Children
