import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Subheading,
  SubheadingType,
  Text, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  InsurerMerOption,
} from '@root/domain/Insurer'

import CallbackForm from '../CallbackForm'

const RequestCallbackAction: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const {
    t,
  } = useTranslation(['offers'])

  const {
    fetchUser: {
      data: user,
    },
  } = useGetUserFromEstimationIdRouteParam()

  if (!user) {
    return null
  }

  return (
    <>
      <Subheading type={SubheadingType.TERTIARY}>
        {t('offers:mer.request_callback.title')}
      </Subheading>

      <Text variant={TextVariant.REGULAR}>
        {t('offers:mer.request_callback.description')}
      </Text>

      <CallbackForm offerId={offerId as string} estimationId={estimationId as string}
        type={InsurerMerOption.CALLBACK_REQUEST}
        user={user}
      />
    </>
  )
}

export default RequestCallbackAction
