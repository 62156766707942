export default {
  modal: {
    help: {
      title: 'Vous avez besoin d’aide ?',
      description: 'Nos conseillers sont disponibles pour répondre à vos questions par téléphone.',
    },
    subscription: {
      title: 'Souscrire à ',
      primaryButton: 'Souscrire en ligne (3 min)',
      secondaryButton: 'Rappel gratuit de l’assureur',
    },
    subscriptionOnline: {
      description: 'Nous vous redirigeons vers ',
    },
    callback: {
      title: 'vous rappelle gratuitement',
      item1: 'Sans engagement',
      item2: 'Un conseiller {{insurer}} à votre service',
      phoneNumber: 'Votre numéro de téléphone',
      button: 'Rappelez-moi',
    },
    callbackWaiting: {
      description: 'analyse de données en cours...',
    },
    callbackDone: {
      title: 'Vos coordonnées ont été transmises avec succès à {{insurer}} ',
      description: {
        immediate: 'Vous serez rappelé dans les plus brefs délais au ',
        delayed: 'Vous serez rappelé à l’horaire prévu au ',
      },
    },
  },
}
