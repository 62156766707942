import * as Sentry from '@sentry/react'
import {
  BrowserTracing,
} from '@sentry/browser'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.MODE,
  })
}
