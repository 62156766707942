import {
  apiService,
} from '@services/apiService'

export interface Profession {
  key: string,
  label: string,
  id: string,
}

interface ProfessionResponse {
  items: Profession[];
  mostPopularProfessions: Profession[];
}

export const ProfessionApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getProfession: builder.query<ProfessionResponse, void>({
      query: () => 'api/health/professions',
    }),
  }),
})

export const {
  useGetProfessionQuery,
} = ProfessionApi
