import {
  apiService,
} from '@services/apiService'

interface Level {
  id: string,
  label: string,
  value: string,
}

interface LevelItem {
  key: string,
  label: string,
}

interface LevelResponse {
  items: LevelItem[];
}

export const LevelApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getLevel: builder.query<Level[], void>({
      query: () => 'api/health/coverage-levels',
      transformResponse: (response: LevelResponse) => {
        return response.items.map(item => ({
          id: item.key,
          label: item.label,
          value: item.key,
        }))
      },
    }),
  }),
})

export const {
  useGetLevelQuery,
} = LevelApi
