import {
  useTranslation,
} from 'react-i18next'
import {
  Button, ButtonSize, ButtonType, getErrorMessages, ErrorMessages, InputGroup,
  TextInput,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useEffect,
  useState,
} from 'react'
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import {
  v4,
} from 'uuid'
import {
  useNavigate,
} from 'react-router-dom'

import {
  CallbackDate,
  callbackDateNow,
} from '@root/domain/OfferMer'
import {
  User,
} from '@root/domain/User'
import {
  PhoneNumber,
} from '@root/domain/Wizard'
import CallbackTimeInput, {
  CallbackTime,
} from '@components/form/CallbackTimeInput'
import CallbackDateInput from '@components/form/CallbackDateInput'
import dayjs from '@root/plugins/day'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import useGetInputStatus from '@hooks/useGetInputStatus'
import ArrowRight from '@assets/icons/arrow-right.svg?react'
import {
  InsurerMerOption,
} from '@root/domain/Insurer'
import {
  ImmediateMerCallbackDate, ScheduledMerCallbackDate,
} from '@root/domain/crm/Record'
import {
  useUpdateUserMutation,
} from '@root/services/user'
import {
  useCreateOfferMerMutation,
} from '@root/services/offers'
import LoadingSpinner from '@components/ui/LoadingSpinner'

import useGetCompletedMer from './useGetCompletedMer'

interface CallbackFormProps {
  offerId: string,
  estimationId: string,
  user: User,
  type: Extract<InsurerMerOption, InsurerMerOption.CALLBACK_REQUEST | InsurerMerOption.CALLBACK_REQUEST_SELECTRA>,
}

interface CallbackFormState {
  phoneNumber: PhoneNumber,
  callback_time: CallbackTime | null,
  callback_date: CallbackDate,
  firstName: string,
  lastName: string,
}

export const formatCallbackDateTime = (date: CallbackDate | null, time: CallbackTime | null): ImmediateMerCallbackDate | ScheduledMerCallbackDate => {
  if (date?.id === 'now') {
    return {
      isImmediate: true,
      date: null,
    }
  }

  return {
    isImmediate: false,
    date: dayjs(`${date?.id} ${time?.id}`).toISOString(),
  }
}

const CallbackForm: FC<CallbackFormProps> = ({
  offerId,
  estimationId,
  user,
  type,
}) => {
  const navigate = useNavigate()
  const {
    t,
  } = useTranslation(['common', 'wizard'])

  const [id] = useState<string>(v4())

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<CallbackFormState>({
    defaultValues: {
      callback_time: null,
      callback_date: callbackDateNow,
      phoneNumber: {
        number: user.phoneNumber,
      },
      firstName: user.firstName,
      lastName: user.lastName,
    },
  })

  const [updateUser] = useUpdateUserMutation()
  const [createMer, {
    isLoading,
    isSuccess: isMerCreateSuccess,
  }] = useCreateOfferMerMutation()

  const {
    isLoading: isLoadingMer,
    isFinished,
    inProgress,
  } = useGetCompletedMer({
    offerId,
    id,
    isMerCreateSuccess,
  })

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  useEffect(() => {
    if (isFinished) {
      navigate(`/offre/${estimationId}/${offerId}/mer/callback/${id}`)
    }
  }, [isFinished, id, navigate, offerId, estimationId])

  const isDisabled = isSubmitting || isLoading || isLoadingMer || inProgress
  const iconRight = isDisabled ? () => <LoadingSpinner size='sm' className='fill-zinc-800' /> : () => <ArrowRight className='fill-white' />

  const onSubmitHandler:SubmitHandler<CallbackFormState> = async data => {
    await updateUser({
      uuid: user.id,
      number: data.phoneNumber.number,
      country_code: 'FR',
      firstName: data.firstName,
      lastName: data.lastName,
    })

    await createMer({
      id,
      type,
      offerId,
      callback_date: formatCallbackDateTime(callbackDate, callbackTime),
    })
  }

  const callbackDate = watch('callback_date')
  const callbackTime = watch('callback_time')

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    // required: t('validation:validation.form.addPhoneNumber'),
    // phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
  }, errors.phoneNumber?.number?.type)

  const dateInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callback_date?.type)

  const dateTimeInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callback_time?.type)

  const firstNameInputStatus = useGetInputStatus(errors.firstName?.type)
  const lastNameInputStatus = useGetInputStatus(errors.lastName?.type)

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <div className='flex flex-col gap-6'>

        <InputGroup errors={getErrorMessages({
          ...ErrorMessages,
        }, errors.firstName?.type)}
        >
          <TextInput
            label={t('wizard:wizard.steps.name.firstName.label')}
            {...register('firstName', {
              required: true,
            })}
            status={firstNameInputStatus}
            dataCy="first-name"
          />
        </InputGroup>

        <InputGroup errors={getErrorMessages({
          ...ErrorMessages,
        }, errors.lastName?.type)}
        >
          <TextInput
            label={t('wizard:wizard.steps.name.lastName.label')}
            {...register('lastName', {
              required: true,
            })}
            status={lastNameInputStatus}
            dataCy="last-name"
          />
        </InputGroup>

        <InputGroup errors={inputErrors}>
          <PhoneNumberInput<CallbackFormState>
            label={t('wizard:wizard.steps.phoneNumber.label')}
            name='phoneNumber.number'
            register={register}
            status={phoneNumberInputStatus}
          />
        </InputGroup>

        <InputGroup errors={dateInputErrors}>
          <Controller
            name='callback_date'
            control={control}
            rules={{
              required: true,
            }}
            render={renderParams => {
              const {
                field: {
                  value,
                  ref,
                  name,
                  onChange,
                },
              } = renderParams

              return (
                <CallbackDateInput value={value} ref={ref}
                  name={name} onChange={onChange}
                  error={errors.callback_date?.type}
                />
              )
            }}
          />
        </InputGroup>

        { callbackDate && callbackDate.id !== 'now' &&
          <InputGroup errors={dateTimeInputErrors}>
            <Controller
              name='callback_time'
              control={control}
              rules={{
                required: true,
              }}
              render={renderParams => {
                const {
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                  },
                } = renderParams

                return (
                  <CallbackTimeInput value={value} ref={ref}
                    name={name} onChange={onChange}
                    error={errors.callback_time?.type}
                  />
                )
              }}
            />
          </InputGroup>
        }
      </div>

      <div className='mt-6'>
        <Button
          type='submit'
          disabled={isDisabled}
          label={'Rappelez-moi'}
          variant={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM}
          pill
          iconRight={iconRight}
        />
      </div>

    </form>
  )
}

export default CallbackForm
