export default {
  wizard: {
    steps: {
      insuranceGender: {
        items: {
          men: 'Un homme',
          women: 'Une femme',
        },
      },
      birthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/1980',
      },
      profession: {
        label: 'Autres professions',
      },
      socialRegime: {
        label: 'Régime social',
        description: '88% des Français sont couverts par le régime général de la Sécurité Sociale.',
      },
      children: {
        label: 'Nombre d\'enfants',
      },
      childrenBirthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/2024',
      },
      postalCode: {
        label: 'Code postal',
        description: 'Ex : 75000 Paris',
      },
      insuranceDate: {
        label: 'Autre date',
        description: 'Ex : 31/01/2024',
        today: 'Aujourd\'hui',
        tomorrow: 'Demain',
      },
      name: {
        firstName: {
          label: 'Prénom',
        },
        lastName: {
          label: 'Nom',
        },
      },
      email: {
        label: 'Adresse e-mail',
        nextButton: 'Voir les offres',
        checkbox: {
          accept_partners: 'J\'accepte qu’un conseiller Selectra m’aide dans la comparaison de contrat de mutuelle santé.',
        },
      },
      regularHealthLevel: {
        description: 'Soins courants : visites chez le généraliste, radiographies, médicaments et analyses.',
      },
      hospitalLevel: {
        description: 'Hospitalisation : frais de séjour, actes des médecins et chambres particulières en hôpital.',
      },
      opticalLevel: {
        description: 'Optique : une monture avec verres complexes et lentilles.',
      },
      dentalLevel: {
        description: 'Dentaire : l’orthodontie, des soins et prothèses dentaires.',
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        description: 'Ex : 06 86 52 04 12',
      },
      next: 'Suivant',
      coverageLevel: {
        MIN: {
          regularHealthLevel: 'Rendez-vous chez généraliste ou spécialiste peu fréquents.',
          hospitalLevel: 'Aucun remboursement des dépassements d\'honoraires ni de la chambre particulière. Les soins sont remboursés à hauteur de 100% de la base de remboursement.',
          opticalLevel: 'Vous ne portez pas de lunettes.',
          dentalLevel: 'Dentition saine, consultations de contrôle chez le dentiste (détartrage, petites caries…).',
        },
        LOW: {
          regularHealthLevel: 'Consultation chez généraliste ou spécialiste aux tarifs conventionnels, ne pratiquant pas de dépassements d\'honoraires.',
          hospitalLevel: 'Couverture des honoraires jusqu\'à 150% et prise en charge faible de la chambre particulière.',
          opticalLevel: 'Vous portez des lunettes à verres simples ou complexes et souhaitez une prise en charge faible moins de 200€ par an.',
          dentalLevel: 'Soins et prothèses éligibles au "100% santé", sans reste à charge.',
        },
        MEDIUM: {
          regularHealthLevel: 'Consultation chez généraliste ou spécialiste avec dépassements d\'honoraires moyens.',
          hospitalLevel: 'Couverture des honoraires jusqu\'à 200% et prise en charge moyenne de la chambre particulière.',
          opticalLevel: 'Vous portez des lunettes à verres simples ou complexes et souhaitez une prise en charge moyenne entre 200€ et 300€ par an ainsi que la prise en charge de vos lentilles.',
          dentalLevel: 'Soins d\'orthodontie, prothèses, inlays, onlays avec couverture moyenne.',
        },
        HIGH: {
          regularHealthLevel: 'Consultation chez généraliste ou spécialiste avec dépassements d\'honoraires forts.',
          hospitalLevel: 'Couverture des honoraires supérieure à 200% et prise en charge important de la chambre particulière.',
          opticalLevel: 'Vous portez des lunettes à verres simples ou complexes et souhaitez une prise en charge importante supérieure à 300€ par an ainsi que la prise en charge de vos lentilles et la prise en charge d\'une chirurgie réfractive.',
          dentalLevel: 'Soins d\'orthodontie, prothèses, inlays, onlays avec couverture importante.',
        },
      },
    },
  },
}
