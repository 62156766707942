import {
  generatePath, useNavigate, useParams,
} from 'react-router-dom'

import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

import {
  getComputedNextStep,
  getComputedPreviousStep,
} from './helpers'

const useAdultStepHandlers = (
  nextStep: wizardRoutes,
  previousStep: wizardRoutes,
  maxSteps: number
) => {
  const navigate = useNavigate()
  const {
    id,
  } = useParams<{id: string}>()

  const currentStep = parseInt(id as string, 10)

  const isLastStep = previousStep === wizardRoutes.SOCIAL_REGIME
  const isFirstStep = previousStep === wizardRoutes.DENTAL_LEVEL

  const isLastIteration = currentStep + 1 === maxSteps
  const isFirstIteration = currentStep === 0

  const computedNextStep = getComputedNextStep(isLastStep, isLastIteration, nextStep)
  const computedPreviousStep = getComputedPreviousStep(isFirstStep, isFirstIteration, previousStep)
  const nextId = isLastStep ? currentStep + 1 : currentStep
  const previousId = isFirstStep ? currentStep - 1 : currentStep

  const nextPath = generatePath(computedNextStep, {
    id: nextId.toString(),
  })

  const prevPath = generatePath(computedPreviousStep, {
    id: previousId.toString(),
  })

  const index = parseInt(id as string, 10)

  return {
    id: index,
    backHandler: () => {
      navigate(`/${prevPath}`)
    },
    nextHandler: () => {
      navigate(`/${nextPath}`)
    },
  }
}

export default useAdultStepHandlers
