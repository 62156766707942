import {
  AdultInformation,
} from '@root/domain/Adult'
import {
  Children,
  Step,
  WizardFormData,
} from '@root/domain/Wizard'

export const findCurrentStep = (steps: Step[], currentStep: Step, adults: AdultInformation[], children: Children[], adultIteration: number, childIteration: number, state: WizardFormData) => {
  const adultSteps = steps.filter(step => step.path.includes('information-beneficiaire'))
  const currentStepIndex = steps.findIndex(step => step.path === currentStep.path)
  const currentAdultStepIndex = adultSteps.findIndex(step => step.path === currentStep.path)

  const test = steps.reduce((acc, step) => {
    const stepIndex = steps.findIndex(stepItem => stepItem.path === step.path)
    const adultStepIndex = adultSteps.findIndex(stepItem => stepItem.path === step.path)
    const isAdultStep = step.path.includes('information-beneficiaire')
    const isChildStep = step.path.includes('age-enfant')

    if (!step.shouldRender(state)) {
      return acc
    }

    if (isAdultStep) {
      if (currentAdultStepIndex >= 0 && currentAdultStepIndex >= adultStepIndex) {
        acc += 1
      }

      acc += adultIteration
    } else if (isChildStep) {
      if (currentStepIndex >= 0 && currentStepIndex >= stepIndex) {
        acc += 1
      }
      acc += childIteration
    } else if (stepIndex <= currentStepIndex) {
      acc += 1
    }

    return acc
  }, 0)

  return test
}
