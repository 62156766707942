import {
  Insurer,
} from '@root/domain/Insurer'
import {
  apiService,
} from '@services/apiService'

interface InsurerResponse {
  items: Insurer[];
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getInsurers: builder.query<Insurer[], void>({
      query: () => ({
        url: 'api/insurers',
        params: {
          filters: {
            is_health_insurer: {
              eq: 1,
            },
          },
        },
      }),
      transformResponse: (response: InsurerResponse) => {
        return response.items
      },
    }),
  }),
})

export const {
  useGetInsurersQuery,
} = insurerApi
