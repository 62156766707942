import {
  apiService,
} from '@services/apiService'

export interface SocialRegime {
  id:string,
  label: string,
  key: string,
}

interface SocialRegimeResponse {
  items: SocialRegime[];
}

export const SocialRegimeApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getSocialRegime: builder.query<SocialRegime[], void>({
      query: () => 'api/health/social-regimes',
      transformResponse: (response: SocialRegimeResponse) => response.items,
    }),
  }),
})

export const {
  useGetSocialRegimeQuery,
} = SocialRegimeApi
