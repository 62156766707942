import React from 'react'

import Sprite from '@assets/sprite.svg'

interface IconProps {
  size?: number;
  name: string;
  className?: string;
  width?: number;
  height?: number;
}

const Icon: React.FC<IconProps> = ({
  size, name, className, width, height,
}) => {
  const widthAttribute = size || width
  const heightAttribute = size || height

  return (
    <svg className={`${className}`} width={widthAttribute}
      height={heightAttribute}
    >
      <use href={`${Sprite}#${name}`} />
    </svg>
  )
}

export default Icon
