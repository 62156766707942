import {
  configureStore,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  setupListeners,
} from '@reduxjs/toolkit/query'

import {
  ValidationExceptionMiddleware,
} from '@root/services/middleware/ValidationExceptionMiddleware'
import {
  apiService,
} from '@root/services/apiService'

import {
  rootReducer,
} from './Reducer'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    apiService.reducerPath
  ],
}

export const createStore = (preloadedState = {}) => {
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiService.middleware).concat(ValidationExceptionMiddleware),
    preloadedState,
    devTools: import.meta.env.DEV,
  })

  const persistor = persistStore(store)

  setupListeners(store.dispatch)

  return {
    store,
    persistor,
  }
}
