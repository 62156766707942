import {
  FC,
} from 'react'

import {
  Document,
} from '@root/domain/Offer'

import DocumentListItem from './DocumentListItem'

interface DocumentListProps {
  documents: Document[],
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
}) => {
  return (
    <div className='mb-6 mt-4 flex flex-col'>
      <h3 className='font-semibold text-neutral-500 md:text-lg'>Informations complémentaires</h3>

      {documents.map(document => {
        return (
          <DocumentListItem key={document.id} document={document}/>
        )
      })}
    </div>
  )
}

export default DocumentList
