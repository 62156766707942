import {
  useTranslation,
} from 'react-i18next'

import {
  Tags,
} from '@root/services/offers'
import {
  tagColors,
} from '@hooks/offers/useGetEstimationTags'

export interface TagLabelProps {
  tag: keyof Tags
}

const TagContainer = ({
  tag,
}: TagLabelProps) => {
  const {
    t,
  } = useTranslation(['offers'])
  const color = tagColors[tag]

  return (
    <div className={` -mb-4 hidden flex-col items-start rounded-t-lg px-4 pb-6 pt-2 md:flex ${color.background}`}>
      <h3 className='flex items-center text-sm font-bold leading-[16px] text-white'>{t(`offers:tags.${tag}`)}</h3>
    </div>
  )
}

export default TagContainer
