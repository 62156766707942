import {
  PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  Offer,
} from '@root/domain/Offer'
import {
  Nullable,
} from '@root/domain/Wizard'
import {
  RootState,
} from '@root/store/Reducer'

export const SUBSCRIPTION_MODAL = 'subscription'

type MODALS = typeof SUBSCRIPTION_MODAL
interface ModalState {
  [SUBSCRIPTION_MODAL]: boolean,
  offer: Nullable<Offer>
}

const initialState: ModalState = {
  [SUBSCRIPTION_MODAL]: false,
  offer: null,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleSubscriptionModal: state => {
      state.subscription = !state.subscription
    },
    openSubscriptionModal: (state, action: PayloadAction<Offer>) => {
      state.subscription = true
      state.offer = action.payload
    },
  },
})

export const isModalOpen = createSelector(
  (state: RootState) => state,
  (state: RootState, modal: MODALS) => modal,
  (state: RootState, modal: MODALS) => state.modal[modal]
)

export const getOffer = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.modal.offer
)

export const {
  toggleSubscriptionModal,
  openSubscriptionModal,
} = modalSlice.actions

export default modalSlice.reducer
