import {
  Button,
  ButtonSize,
  ButtonType,
} from '@selectra-it/selectra-ui'

import ArrowRight from '@assets/icons/arrow-right.svg?react'
import useEstimationWizardForm from '@hooks/useWizardForm'

const Presentation = () => {
  const {
    onSubmitHandler,
  } = useEstimationWizardForm({})

  return (
    <form
      className="flex w-full grow flex-col justify-between space-y-6"
      onSubmit={onSubmitHandler()}
    >
      <p />
      <Button
        label='Allons-y'
        variant={ButtonType.SUCCESS}
        size={ButtonSize.LARGE}
        iconRight={ArrowRight}
        pill
      />
    </form>

  )
}

export default Presentation
