import {
  FC,
} from 'react'
import {
  InteractionMessage,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import {
  User,
} from '@root/domain/User'
import {
  Offer,
} from '@root/domain/Offer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectActiveInsurerCount,
} from '@root/services/insurers/selectors'

interface OfferMessageProps {
  isSuccess: boolean
  user: User | undefined
  offers: Offer[] | undefined
}

const OfferMessage: FC<OfferMessageProps> = ({
  isSuccess, user, offers,
}) => {
  const {
    t,
  } = useTranslation(['offers'])

  const insurerCount = useAppSelector(state => selectActiveInsurerCount(state))

  if (!isSuccess || !user) return null

  const messages = offers?.length
    ? [`${t('offers:offers.message1')}`, t('offers:offers.message2', {
      insurerCount,
    })]
    : [`${t('offers:noOffers.message1')}`, `${t('offers:noOffers.message2')} <a href="tel: +0186266383"><strong>01 86 26 63 83</strong></a> ${t('offers:noOffers.message3')}`]

  return (
    <div className='container my-0 max-w-xl px-4 md:px-0'>
      <InteractionMessage messages={messages}/>
    </div>
  )
}

export default OfferMessage
