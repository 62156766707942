import {
  FC,
} from 'react'
import {
  Subheading, SubheadingType,
} from '@selectra-it/selectra-ui'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'
import OfferProvider from '@root/pages/Offers/OfferItem/OfferProvider'

const MerHeader: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!offer || !insurer) {
    return null
  }

  const {
    providerId,
  } = offer

  const Icon = insurerLogos[insurer.id]

  return (
    <div className="flex gap-2">
      <div className="flex grow flex-col gap-2">
        <div>
          {typeof Icon === 'string'
            ? <img src={Icon} className='h-10'/>
            : <Icon height={40} />}
        </div>
        <Subheading type={SubheadingType.TERTIARY}>
          {offer.title}
        </Subheading>
        {providerId && <OfferProvider providerId={providerId} />}
      </div>
      <Price price={offer.monthlyPrice} />
    </div>
  )
}

export default MerHeader
