const formatDate = (inputDate: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  const formattedDate: string = new Intl.DateTimeFormat('fr-FR', options).format(inputDate)

  return formattedDate
}

export {
  formatDate
}
