import {
  FC, SVGProps,
} from 'react'

export enum Beneficiary {
  PRINCIPAL = 'PRINCIPAL',
  PRINCIPAL_AND_CHILDREN = 'PRINCIPAL_AND_CHILDREN',
  PRINCIPAL_SPOUSE_AND_CHILDREN = 'PRINCIPAL_SPOUSE_AND_CHILDREN',
  PRINCIPAL_AND_SPOUSE = 'PRINCIPAL_AND_SPOUSE',
}

export const beneficiaryHasChildren: Record<Beneficiary, boolean> = {
  [Beneficiary.PRINCIPAL]: false,
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: true,
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: true,
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: false,
}

export const numberOfAdults: Record<Beneficiary, number> = {
  [Beneficiary.PRINCIPAL]: 1,
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: 1,
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: 2,
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: 2,
}

export const BeneficiaryIcons: Record<Beneficiary, FC<SVGProps<SVGSVGElement>>> = {
  [Beneficiary.PRINCIPAL]: () => (
    <svg width="48" height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6926_283)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M49.5 -2H47.5V0H49.5V-2ZM21 30V42C21 42.5667 21.1917 43.0417 21.575 43.425C21.9583 43.8083 22.4333 44 23 44H25C25.5667 44 26.0417 43.8083 26.425 43.425C26.8083 43.0417 27 42.5667 27 42V30H30C30.5667 30 31.0417 29.8083 31.425 29.425C31.8083 29.0417 32 28.5667 32 28V18C32 16.9 31.6083 15.9583 30.825 15.175C30.0417 14.3917 29.1 14 28 14H20C18.9 14 17.9583 14.3917 17.175 15.175C16.3917 15.9583 16 16.9 16 18V28C16 28.5667 16.1917 29.0417 16.575 29.425C16.9583 29.8083 17.4333 30 18 30H21ZM21.175 10.825C21.9583 11.6083 22.9 12 24 12C25.1 12 26.0417 11.6083 26.825 10.825C27.6083 10.0417 28 9.1 28 8C28 6.9 27.6083 5.95833 26.825 5.175C26.0417 4.39167 25.1 4 24 4C22.9 4 21.9583 4.39167 21.175 5.175C20.3917 5.95833 20 6.9 20 8C20 9.1 20.3917 10.0417 21.175 10.825Z" fill="current"
        />
      </g>
      <defs>
        <clipPath id="clip0_6926_283">
          <rect width="48" height="48"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: () => (
    <svg width="48" height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 42V30H11C10.4333 30 9.95833 29.8083 9.575 29.425C9.19167 29.0417 9 28.5667 9 28V18C9 16.9 9.39167 15.9583 10.175 15.175C10.9583 14.3917 11.9 14 13 14H21C22.1 14 23.0417 14.3917 23.825 15.175C24.6083 15.9583 25 16.9 25 18V28C25 28.5667 24.8083 29.0417 24.425 29.425C24.0417 29.8083 23.5667 30 23 30H20V42C20 42.5667 19.8083 43.0417 19.425 43.425C19.0417 43.8083 18.5667 44 18 44H16C15.4333 44 14.9583 43.8083 14.575 43.425C14.1917 43.0417 14 42.5667 14 42ZM17 12C15.9 12 14.9583 11.6083 14.175 10.825C13.3917 10.0417 13 9.1 13 8C13 6.9 13.3917 5.95833 14.175 5.175C14.9583 4.39167 15.9 4 17 4C18.1 4 19.0417 4.39167 19.825 5.175C20.6083 5.95833 21 6.9 21 8C21 9.1 20.6083 10.0417 19.825 10.825C19.0417 11.6083 18.1 12 17 12Z" fill="current"/>
      <path d="M32.125 42.75V35.25H30.25C29.8958 35.25 29.599 35.1302 29.3594 34.8906C29.1198 34.651 29 34.3542 29 34V27.75C29 27.0625 29.2448 26.474 29.7344 25.9844C30.224 25.4948 30.8125 25.25 31.5 25.25H36.5C37.1875 25.25 37.776 25.4948 38.2656 25.9844C38.7552 26.474 39 27.0625 39 27.75V34C39 34.3542 38.8802 34.651 38.6406 34.8906C38.401 35.1302 38.1042 35.25 37.75 35.25H35.875V42.75C35.875 43.1042 35.7552 43.401 35.5156 43.6406C35.276 43.8802 34.9792 44 34.625 44H33.375C33.0208 44 32.724 43.8802 32.4844 43.6406C32.2448 43.401 32.125 43.1042 32.125 42.75ZM34 24C33.3125 24 32.724 23.7552 32.2344 23.2656C31.7448 22.776 31.5 22.1875 31.5 21.5C31.5 20.8125 31.7448 20.224 32.2344 19.7344C32.724 19.2448 33.3125 19 34 19C34.6875 19 35.276 19.2448 35.7656 19.7344C36.2552 20.224 36.5 20.8125 36.5 21.5C36.5 22.1875 36.2552 22.776 35.7656 23.2656C35.276 23.7552 34.6875 24 34 24Z" fill="current"/>
    </svg>
  ),
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: () => (
    <svg width="48" height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 42V30H3C2.43333 30 1.95833 29.8083 1.575 29.425C1.19167 29.0417 1 28.5667 1 28V18C1 16.9 1.39167 15.9583 2.175 15.175C2.95833 14.3917 3.9 14 5 14H13C14.1 14 15.0417 14.3917 15.825 15.175C16.6083 15.9583 17 16.9 17 18V28C17 28.5667 16.8083 29.0417 16.425 29.425C16.0417 29.8083 15.5667 30 15 30H12V42C12 42.5667 11.8083 43.0417 11.425 43.425C11.0417 43.8083 10.5667 44 10 44H8C7.43333 44 6.95833 43.8083 6.575 43.425C6.19167 43.0417 6 42.5667 6 42ZM9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12Z" fill="current"/>
      <path d="M22.125 42.75V35.25H20.25C19.8958 35.25 19.599 35.1302 19.3594 34.8906C19.1198 34.651 19 34.3542 19 34V27.75C19 27.0625 19.2448 26.474 19.7344 25.9844C20.224 25.4948 20.8125 25.25 21.5 25.25H26.5C27.1875 25.25 27.776 25.4948 28.2656 25.9844C28.7552 26.474 29 27.0625 29 27.75V34C29 34.3542 28.8802 34.651 28.6406 34.8906C28.401 35.1302 28.1042 35.25 27.75 35.25H25.875V42.75C25.875 43.1042 25.7552 43.401 25.5156 43.6406C25.276 43.8802 24.9792 44 24.625 44H23.375C23.0208 44 22.724 43.8802 22.4844 43.6406C22.2448 43.401 22.125 43.1042 22.125 42.75ZM24 24C23.3125 24 22.724 23.7552 22.2344 23.2656C21.7448 22.776 21.5 22.1875 21.5 21.5C21.5 20.8125 21.7448 20.224 22.2344 19.7344C22.724 19.2448 23.3125 19 24 19C24.6875 19 25.276 19.2448 25.7656 19.7344C26.2552 20.224 26.5 20.8125 26.5 21.5C26.5 22.1875 26.2552 22.776 25.7656 23.2656C25.276 23.7552 24.6875 24 24 24Z" fill="current"/>
      <path d="M36 42V30H33C32.4333 30 31.9583 29.8083 31.575 29.425C31.1917 29.0417 31 28.5667 31 28V18C31 16.9 31.3917 15.9583 32.175 15.175C32.9583 14.3917 33.9 14 35 14H43C44.1 14 45.0417 14.3917 45.825 15.175C46.6083 15.9583 47 16.9 47 18V28C47 28.5667 46.8083 29.0417 46.425 29.425C46.0417 29.8083 45.5667 30 45 30H42V42C42 42.5667 41.8083 43.0417 41.425 43.425C41.0417 43.8083 40.5667 44 40 44H38C37.4333 44 36.9583 43.8083 36.575 43.425C36.1917 43.0417 36 42.5667 36 42ZM39 12C37.9 12 36.9583 11.6083 36.175 10.825C35.3917 10.0417 35 9.1 35 8C35 6.9 35.3917 5.95833 36.175 5.175C36.9583 4.39167 37.9 4 39 4C40.1 4 41.0417 4.39167 41.825 5.175C42.6083 5.95833 43 6.9 43 8C43 9.1 42.6083 10.0417 41.825 10.825C41.0417 11.6083 40.1 12 39 12Z" fill="current"/>
    </svg>
  ),
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: () => (
    <svg width="48" height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 42V30H8C7.43333 30 6.95833 29.8083 6.575 29.425C6.19167 29.0417 6 28.5667 6 28V18C6 16.9 6.39167 15.9583 7.175 15.175C7.95833 14.3917 8.9 14 10 14H18C19.1 14 20.0417 14.3917 20.825 15.175C21.6083 15.9583 22 16.9 22 18V28C22 28.5667 21.8083 29.0417 21.425 29.425C21.0417 29.8083 20.5667 30 20 30H17V42C17 42.5667 16.8083 43.0417 16.425 43.425C16.0417 43.8083 15.5667 44 15 44H13C12.4333 44 11.9583 43.8083 11.575 43.425C11.1917 43.0417 11 42.5667 11 42ZM14 12C12.9 12 11.9583 11.6083 11.175 10.825C10.3917 10.0417 10 9.1 10 8C10 6.9 10.3917 5.95833 11.175 5.175C11.9583 4.39167 12.9 4 14 4C15.1 4 16.0417 4.39167 16.825 5.175C17.6083 5.95833 18 6.9 18 8C18 9.1 17.6083 10.0417 16.825 10.825C16.0417 11.6083 15.1 12 14 12Z" fill="current"/>
      <path d="M31 42V30H28C27.4333 30 26.9583 29.8083 26.575 29.425C26.1917 29.0417 26 28.5667 26 28V18C26 16.9 26.3917 15.9583 27.175 15.175C27.9583 14.3917 28.9 14 30 14H38C39.1 14 40.0417 14.3917 40.825 15.175C41.6083 15.9583 42 16.9 42 18V28C42 28.5667 41.8083 29.0417 41.425 29.425C41.0417 29.8083 40.5667 30 40 30H37V42C37 42.5667 36.8083 43.0417 36.425 43.425C36.0417 43.8083 35.5667 44 35 44H33C32.4333 44 31.9583 43.8083 31.575 43.425C31.1917 43.0417 31 42.5667 31 42ZM34 12C32.9 12 31.9583 11.6083 31.175 10.825C30.3917 10.0417 30 9.1 30 8C30 6.9 30.3917 5.95833 31.175 5.175C31.9583 4.39167 32.9 4 34 4C35.1 4 36.0417 4.39167 36.825 5.175C37.6083 5.95833 38 6.9 38 8C38 9.1 37.6083 10.0417 36.825 10.825C36.0417 11.6083 35.1 12 34 12Z" fill="current"/>
    </svg>
  ),
}
