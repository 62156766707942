import {
  Outlet,
  useSearchParams,
} from 'react-router-dom'

import SubscriptionModal from '@components/ui/SubscriptionModal/SubscriptionModal'

import Offers from './Offers'
import LoadingPage from './LoadingPage/LoadingPage'

const OffersPage = () => {
  const [searchParams] = useSearchParams()

  return (
    <>
      {(searchParams.get('preload')) && <LoadingPage />}
      <Offers />
      <SubscriptionModal />
      <Outlet />
    </>
  )
}

export default OffersPage
