import {
  Stepper as BaseStepper,
  Status,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  matchPath,
  useLocation,
} from 'react-router-dom'

import {
  StepperGroup,
  steps,
} from '@root/routes/wizard'

const StepperGroupOrder: Array<StepperGroup> = [StepperGroup.COVERAGE, StepperGroup.BENEFICIARIES, StepperGroup.CONTACT_INFORMATION]

const StepperSteps = [
  {
    step: 1,
    name: 'Votre couverture',
    status: 2,
  },
  {
    step: 2,
    name: 'Votre profil',
    status: 0,
  },
  {
    step: 3,
    name: 'Vos coordonnées',
    status: 1,
  }
]

const subRoutes: { [key: string]: number } = {
  '/information-beneficiaire': 0,
  '/nombre-enfants': 1,
}

const Stepper: FC = () => {
  const location = useLocation()

  const pathname = `/${location.pathname.split('/').at(1)}`

  const currentStep = steps.find(step => matchPath(step.path, pathname))

  let stepGroupIndex = StepperGroupOrder.findIndex(step => step === currentStep?.group)

  if (stepGroupIndex < 0) {
    stepGroupIndex = subRoutes[pathname]
  }

  const computedSteps = StepperSteps.map((step, index) => {
    const status = stepGroupIndex > index
      ? Status.COMPLETED
      : stepGroupIndex === index ? Status.ACTIVE : Status.PENDING

    return {
      ...step,
      status,
    }
  })

  return (
    <BaseStepper steps={computedSteps} />
  )
}

export default Stepper
