import {
  createSelector,
} from '@reduxjs/toolkit'

import {
  RootState,
} from '@root/store/Reducer'

import {
  OffersApi,
} from './index'

const selectOffersByEstimationIdBase = OffersApi.endpoints.getOffersByEstimationId.select

export const selectOffersForEstimationId = (estimationId: string) =>
  selectOffersByEstimationIdBase({
    estimationId,
  })

export const selectOfferById = (estimationId: string, offerId: string) =>
  createSelector(
    (state: RootState) => selectOffersForEstimationId(estimationId)(state as never),
    offers => offers.data?.items.find(offer => offer.id === offerId) || null
  )
