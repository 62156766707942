import {
  FC,
  useRef,
} from 'react'
import {
  InputGroup, getErrorMessages, ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import {
  useGetLevelQuery,
} from '@services/level'
import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'
import useGetRadioStatus from '@hooks/useGetRadioStatus'

const HospitalLevel: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    data: options,
  } = useGetLevelQuery()
  const {
    t,
  } = useTranslation(['wizard'])
  const {
    form: {
      register, watch,
      formState: {
        errors,
        isDirty,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({})

  const errorType = errors.hospitalLevel?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errorType)
  const hospitalLevelStatus = useGetRadioStatus(errors.hospitalLevel?.type)

  useSubmitFormOnChange(isDirty, watch('hospitalLevel'), formRef)

  if (!options) return null

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
      >
        <RadioStackedContainer>
          {options.map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
                description: t(`wizard:wizard.steps.coverageLevel.${id}.hospitalLevel` as 'wizard:wizard.steps.coverageLevel.MIN.hospitalLevel'),
              }}
              status={hospitalLevelStatus}
              {...register('hospitalLevel', {
                required: true,
              })}
              dataCy={value}
            />
          ))}
        </RadioStackedContainer>
      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default HospitalLevel
