import {
  DATE_MASK, InputGroup, getErrorMessages, ErrorMessages, MaskedInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  useDispatch,
} from 'react-redux'
import {
  SubmitHandler,
} from 'react-hook-form'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

import useAdultStepHandlers from './useAdultStepHandlers'

const BirthDate = () => {
  const dispatch = useDispatch()
  const {
    form: {
      handleSubmit,
      register,
      formState: {
        errors,
      },
      watch,
    },

  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    id, backHandler, nextHandler,
  } = useAdultStepHandlers(wizardRoutes.POSTAL_CODE, wizardRoutes.SOCIAL_REGIME, watch('adults').length)

  const errorType = errors.adults?.[id]?.birthDate?.type

  const status = useGetInputStatus(errorType)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addBirthDate'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup description={t('wizard:wizard.steps.birthDate.description')}
          errors={inputErrors}
        >
          <MaskedInput label={t('wizard:wizard.steps.birthDate.label')}
            mask={DATE_MASK}
            status={status}
            {...register(`adults.${id}.birthDate` as 'adults.0.birthDate', {
              required: true,
            })}
            dataCy='birthDate'
          />

        </InputGroup>
      </div>

      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default BirthDate
